<template>
  <div>
    <div class="d-flex justify-start align-center" style="height:50px;color: #d31145;background-color:#fff;padding: 0 0 0 10px">
      <v-icon color="#d31145" @click="backto">mdi-arrow-left</v-icon>
      <span style="padding-left:15px;font-weight:700">
        {{ $route.query.id ? 'Ubah Toko' : 'Tambah Toko' }}
      </span>
    </div>

    <div style="height:calc(100vh - 130px);overflow:auto">
      <v-form>
        <div class="d-flex justify-center align-center py-8">
          <v-card
            flat
            class="pa-0 c-p"
            style="background: transparent;">
            <image-input v-model="avatar" type="uploadCover">
              <div slot="activator">
                <v-avatar
                  tile
                  size="125"
                  class="grey lighten-1 cursor-pointer rounded-xl">
                  <v-img
                    v-if="!avatar && addImage === ''"
                    :src="form.image_url"
                    class="mx-auto"
                    width="125"
                    height="125"
                    gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)">
                    <v-icon
                      v-if="!avatar && addImage === '' && form.image_url === ''"
                      color="white"
                      style="z-index:2"
                      size="40">
                      mdi-camera-plus
                    </v-icon>
                  </v-img>

                  <div
                    class="px-5"
                    v-if="addImage && avatar.url === undefined">
                    <v-progress-linear
                      color="#d31145"
                      indeterminate
                      rounded
                      height="6"
                      class="mx-4 mt-4"
                      style="width: 80px;">
                    </v-progress-linear>

                    <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                      Mengupload Gambar . . .
                    </p>
                  </div>

                  <v-img
                    v-if="avatar && !addImage"
                    :src="form.image_url"
                    :alt="form.image_url"
                    class="ma-auto"
                    gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
                    width="125"
                    height="125">
                    <v-icon
                      color="white"
                      size="40"
                      v-if="avatar && !addImage">
                      mdi-camera-retake
                    </v-icon>
                  </v-img>
                </v-avatar>
              </div>
            </image-input>
          </v-card>

          <p class="caption mt-1 mb-1 red--text" v-show="error.image_url">
            {{ error.image_url }}
          </p>
        </div>

        <v-container class="pa-6">
          <v-row>
            <v-col cols="12">
              <div class="subtitle-2 font-weight-bold text-title">
                Nama Outlet
              </div>

              <v-text-field
                v-model="form.name"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="50"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">
                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.name.length }} 
                  </span>

                  <span style="color:#B5B5B5"> 
                    dari 50 Karakter
                  </span>  
                </div>
              </div>
            </v-col>
            
            <v-col cols="12">
              <div class="subtitle-2 font-weight-bold text-title">
                Nomor Telepon Outlet
              </div>

              <v-text-field
                v-model="form.mobilephone"
                placeholder="Tuliskan disini"
                type="number"
                required
                color="#d31145"
                maxlength="15"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
                <template v-slot:append>
                  <v-icon v-if="formError.mobilephone" color="#d31145">mdi-alert</v-icon>
                </template>
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">
                <div class="text-required">
                  Wajib
                </div>
                <div style="font-size: 11px;">
                  <span style="font-weight:bold">
                    {{ form.mobilephone.length }}
                  </span>

                  <span style="color:#B5B5B5"> 
                    dari 15 Karakter
                  </span>  
                </div>
              </div>
            </v-col>
            
            <v-col cols="12">
              <div class="subtitle-2 font-weight-bold text-title">
                Alamat Outlet
              </div> 

              <v-text-field
                v-model="form.address"
                placeholder="Tuliskan disini"
                color="#d31145"
                maxlength="150"
                required
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
                <template v-slot:append>
                  <v-icon v-if="formError.address" color="#d31145">mdi-alert</v-icon>
                </template>
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">
                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight:bold">
                    {{ form.address.length }}
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 150 Karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="mb-2 subtitle-2 font-weight-bold text-title">
                Diskon Outlet
              </div>

              <div class="d-flex justify-start align-start">
                <div
                  @click="form.discountType = 'nominal'"
                  :style="form.discountType == 'nominal' ? 
                  'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#ffffff; width: 45px; height: 30px; background: #D31145; border-radius: 5px 0px 0px 5px;'
                  : 'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#d31145; width: 45px; height: 30px; background: #F3F3F3; border-radius: 5px 0px 0px 5px;'">
                  Rp
                </div>
                <div
                  @click="form.discountType = 'percent'"
                  :style="
                  form.discountType == 'percent' ?
                    'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#ffffff; width: 45px; height: 30px; background: #D31145; border-radius: 0px 5px 5px 0px;'
                    : 'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#d31145; width: 45px; height: 30px; background: #F3F3F3; border-radius: 0px 5px 5px 0px;'
                    ">
                  %
                </div>

                <div class="pa-1 text-capitalize pl-4" style="color: #ACACAC">
                  {{ form.discountType == 'percent' ? 'Persen' : 'Nominal' }}
                  
                </div>
              </div>

              <v-currency-field
                type="number"
                v-model="form.discountValue"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="10"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-currency-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">
                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">

                  <span style="font-weight:bold">
                    {{ form.discountValue.toString().length }}
                  </span>

                  <span style="color:#B5B5B5"> 
                    dari 10 karakter
                  </span> 
                </div>
              </div>
              
            </v-col>

            <v-col cols="12">
              <div class="mb-2 subtitle-2 font-weight-bold text-title">
                Servis Outlet
              </div>

              <div class="d-flex justify-start align-start">
                <div
                  @click="form.serviceType = 'nominal'"
                  :style="form.serviceType == 'nominal' ? 
                  'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#ffffff; width: 45px; height: 30px; background: #D31145; border-radius: 5px 0px 0px 5px;'
                  : 'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#d31145; width: 45px; height: 30px; background: #F3F3F3; border-radius: 5px 0px 0px 5px;'">
                  Rp
                </div>
                <div
                  @click="form.serviceType = 'percent'"
                  :style="
                  form.serviceType == 'percent' ?
                    'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#ffffff; width: 45px; height: 30px; background: #D31145; border-radius: 0px 5px 5px 0px;'
                    : 'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#d31145; width: 45px; height: 30px; background: #F3F3F3; border-radius: 0px 5px 5px 0px;'
                    ">
                  %
                </div>

                <div class="pa-1 text-capitalize pl-4" style="color: #ACACAC">
                  {{ form.serviceType == 'percent' ? 'Persen' : 'Nominal' }}
                </div>
              </div>

              <v-currency-field
                type="number"
                v-model="form.serviceValue"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="10"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-currency-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">
                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">

                  <span style="font-weight:bold">
                    {{ form.serviceValue.toString().length }}
                  </span>

                  <span style="color:#B5B5B5"> 
                    dari 10 karakter
                  </span> 
                </div>
              </div>
              
            </v-col>

            <v-col cols="12">
              <div class="mb-2 subtitle-2 font-weight-bold text-title">
                Pajak Outlet
              </div>

              <div class="d-flex justify-start align-start">
                <div
                  @click="form.taxType = 'nominal'"
                  :style="form.taxType == 'nominal' ? 
                  'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#ffffff; width: 45px; height: 30px; background: #D31145; border-radius: 5px 0px 0px 5px;'
                  : 'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#d31145; width: 45px; height: 30px; background: #F3F3F3; border-radius: 5px 0px 0px 5px;'">
                  Rp
                </div>
                <div
                  @click="form.taxType = 'percent'"
                  :style="
                  form.taxType == 'percent' ?
                    'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#ffffff; width: 45px; height: 30px; background: #D31145; border-radius: 0px 5px 5px 0px;'
                    : 'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#d31145; width: 45px; height: 30px; background: #F3F3F3; border-radius: 0px 5px 5px 0px;'
                    ">
                  %
                </div>

                <div class="pa-1 text-capitalize pl-4" style="color: #ACACAC">
                  {{ form.taxType == 'percent' ? 'Persen' : 'Nominal' }}
                </div>
              </div>

              <v-currency-field
                type="number"
                v-model="form.taxValue"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="10"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-currency-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">
                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">

                  <span style="font-weight:bold">
                    {{ form.taxValue.toString().length }}
                  </span>

                  <span style="color:#B5B5B5"> 
                    dari 10 karakter
                  </span> 
                </div>
              </div>
              
            </v-col>

            <!-- <v-col cols="12" class="d-flex justify-center">
              <div style="position: absolute; bottom: 20px; width: 92%;">
                <v-btn 
                  @click="toValidation()" 
                  :loading="process.run" 
                  :disabled="process.run" 
                  block 
                  rounded 
                  large
                  elevation="0" 
                  color="#d31145" 
                  class="body-1 d-flex white--text justify-start text-capitalize">
                  <v-icon>mdi-check-circle-outline</v-icon>

                  <span style="display:flex;justify-content:center;width:100%;">
                    Buka Toko
                  </span>
                </v-btn>
              </div>
            </v-col> -->
          </v-row>

        </v-container>

        <div style="position:absolute;bottom:0;width:100%;background-color:#fff;height:60px" class="d-flex justify-center align-center px-6">
          <v-btn 
            @click="toValidation()" 
            :loading="process.run" 
            :disabled="process.run"
            rounded 
            style="flex:1"
            elevation="0"
            color="#d31145" 
            class="text-capitalize white--text mr-2 d-flex justify-space-between">
            <v-icon color="white" left>
              mdi-check-circle-outline
            </v-icon>

            <span>
              {{ $route.query.id ? 'Simpan perubahan' : 'Tambahkan data' }}
            </span>
          </v-btn>

          <v-btn
            @click="backto"
            :loading="process.run" 
            :disabled="process.run" 
            rounded 
            style="flex:.5"
            outlined
            elevation="0" 
            color="#D31145" 
            class="text-capitalize">
            <span>
              Batalkan
            </span>
          </v-btn>
        </div>
      </v-form>

    </div>

    <!-- # BOTTOM SHEET COMFIRMATION  -->
    <v-bottom-sheet v-model="confirmation" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
            {{ $route.query.id ? 'Ubah Toko' : 'Tambah Toko' }}
        </div>

        <v-divider></v-divider>

        <div class="d-flex flex-wrap align-content-center align-center" style="font-size:14px;padding:10px 20px 5px 20px;height:96px;">
          Apakah anda yakin ingin {{ $route.query.id ? 'mengubah' : 'menambahkan' }} toko &nbsp;
          <span class="font-weight-bold text-capitalize">{{ form.name }} </span> &nbsp; ?
        </div>

        <div class="d-flex justify-center align-center px-4 pb-4" style="position:absolute;bottom:0;width:100%">
          <v-btn 
            @click="saveStore()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya, {{ $route.query.id ? 'Simpan Perubahan' : 'Tambahkan Data' }}
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="confirmation = false">
            Batalkan
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- <v-bottom-sheet v-model="confirmation" width="100%">
      <v-sheet
        class="text-left"
        height="180px"
        width="100%">
        <div style="font-size:20px;color:#d31145; font-weight:bold;padding-left:20px;padding-top:20px">
          Daftar & Buka Toko
        </div>

        <div class="body-1 mb-2" style="padding:10px 20px 5px 20px">
          Apakah anda yakin ingin daftar dan buka toko ?
        </div>

        <div class="d-flex justify-star pa-5">
          <v-btn 
            @click="saveStore()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded 
            large
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya Daftar & Buka Toko 
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            width="150" 
            color="red"
            large
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="confirmation = false;$refs.observer.reset()">
            Batal
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet> -->
  </div>
</template>

<script>
import firebaseapp from "firebase";
import firebase from "@/plugins/Firebase";
import ImageInput from "@/components/ImageInput";
export default {
  components: {
    ImageInput,
  },
  data: () => ({
    confirmation: false,
    formError:{},
    form: {
      id: "",
      name: "",
      address: "",
      mobilephone: "",
      image_url: "",
      discountType: "nominal",
      discountValue: 0,
      serviceType: "nominal",
      serviceValue: 0,
      taxType: "nominal",
      taxValue: 0
    },
    avatar: null,
    addImage: "",
    process: {
      run: false
    },
    error: {
      image_url: ""
    }
  }),
  watch: {
    avatar: {
      handler: function() {
        this.form.image_url = this.avatar.url;
        this.addImage = this.avatar.add;
      },
      deep: true,
    },
  },
  computed: {
    user(){
      return this.$store.state.user
    }
  },
  created() {

  },
  mounted(){
    this.avatar = {
      url: "https://sodapos.com/media/2021/12/image/store-1638864048.png",
      add: false
    }

    this.form.image_url = "https://sodapos.com/media/2021/12/image/store-1638864048.png";
    this.addImage = false
    if (this.$route.query.id) {
      this.getDetail()
    }
  },
  methods: {
    async getDetail(){
      this.$store.state.overlay.state = true

      let params = {
        id: this.$route.query.id
      }

      // let res = await this.$get(`pos/store/detail?id=${this.$route.query.id}`)
      let res = await this.$get(`pos/store/detail`, params)

      if (res.status == 200) {
        let item = res.results.data

        this.form = {
          id: item !== undefined ? item.id : '',
          name: item !== undefined ? item.name : '',
          address: item !== undefined ? item.address : '',
          mobilephone: item !== undefined ? item.mobilephone : '',
          image_url: item !== undefined ? item.image_url : 'https://sodapos.com/media/2021/12/image/store-1638864048.png',
          discountType: item !== undefined ? item.discount_type : 'nominal',
          discountValue: item !== undefined ? item.discount_value : 0,
          serviceType: item !== undefined ? item.service_type : 'nominal',
          serviceValue: item !== undefined ? item.service_value : 0,
          taxType: item !== undefined ? item.tax_type : 'nominal',
          taxValue: item !== undefined ? item.tax_value : 0,
        }

        // res.results.data.forEach(item => {
          
        //   if (this.$route.query.id == item.id) {
        //     console.log(item);
        //     this.form = {
        //       id: item !== undefined ? item.id : '',
        //       name: item !== undefined ? item.name : '',
        //       address: item !== undefined ? item.address : '',
        //       mobilephone: item !== undefined ? item.mobilephone : '',
        //       image_url: item !== undefined ? item.image_url : 'https://sodapos.com/media/2021/12/image/store-1638864048.png',
        //       discountType: item !== undefined ? item.discount_type : 'nominal',
        //       discountValue: item !== undefined ? item.discount_value : 0,
        //       serviceType: item !== undefined ? item.service_type : 'nominal',
        //       serviceValue: item !== undefined ? item.service_value : 0,
        //       taxType: item !== undefined ? item.tax_type : 'nominal',
        //       taxValue: item !== undefined ? item.tax_value : 0,
        //     }
        //     console.log(this.form);
        //   }else {
        //     this.form = {
        //       id: "",
        //       name: "",
        //       address: "",
        //       mobilephone: "",
        //       image_url: "https://sodapos.com/media/2021/12/image/store-1638864048.png",
        //       discountType: "nominal",
        //       discountValue: 0,
        //       serviceType: "nominal",
        //       serviceValue: 0,
        //       taxType: "nominal",
        //       taxValue: 0
        //     }
        //   }
        // })
      }


      this.$store.state.overlay.state = false
    },
    toValidation(){
      if(!this.form.name){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nama Outlet tidak boleh kosong"
      }else if(!this.form.mobilephone){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nomor Telepon Outlet tidak boleh kosong"
      }else if(this.form.mobilephone.length < 10){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nomor Telepon Outlet minimal 10 karakter"
      }else if(!this.form.address){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Alamat Outlet tidak boleh kosong"
      }else if(this.form.discountValue == null){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Diskon Outlet tidak boleh kosong"
      }else if(this.form.serviceValue == null){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Servis Outlet tidak boleh kosong"
      }else if(this.form.taxValue == null){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Pajak Outlet tidak boleh kosong"
      }else {
        this.confirmation = true
      }
    },
    async saveStore(){
      this.$store.state.overlay.state = true;
      this.confirmation = false
      this.process.run = true;
      let data = {}
      let url = ""

      if (this.$route.query.id) {
        data = {
          id: this.$route.query.id,
          name: this.form.name,
          address: this.form.address,
          mobilephone: this.form.mobilephone,
          image_url: this.form.image_url,
          discountType: this.form.discountType,
          discountValue: this.form.discountValue,
          serviceType: this.form.serviceType,
          serviceValue: this.form.serviceValue,
          taxType: this.form.taxType,
          taxValue: this.form.taxValue
        }
        url = `pos/store/update`
      }else {
        data = {
          name: this.form.name,
          address: this.form.address,
          mobilephone: this.form.mobilephone,
          image_url: this.form.image_url,
          discountType: this.form.discountType,
          discountValue: this.form.discountValue,
          serviceType: this.form.serviceType,
          serviceValue: this.form.serviceValue,
          taxType: this.form.taxType,
          taxValue: this.form.taxValue
        }
        url = `pos/store/add`
      }

      let res = await this.$post(url, data)
      if(res.status == 200) {
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
        this.confirmation = false
        localStorage.setItem('user', JSON.stringify(res.results))
        window.location = `/master/store`
      }

      if(res.status == 412) {
        Object.keys(res.results.data).forEach((item) => {
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.content = res.results.data[item]
        });
      }

      this.process.run = false
      this.formError = res.results.data
      this.$store.state.overlay.state = false;
    },

    backto() {
      if (this.$store.state.authenticated && !this.$store.state.user.have_store_bool) {
        this.$post(`auth/logout`).then((response) => {
          if (response.status === 200){
            firebase.auth().signOut().then((response) => {
              firebaseapp
              .messaging()
              .getToken()
              .then((oldToken) => {
                if (oldToken === localStorage.getItem("TOKEN_FCM")) {
                  firebaseapp.messaging().deleteToken({ token: oldToken });
                  localStorage.setItem('TOKEN_FCM', '')
                } else {
                  console.log("Token is deleted");
                }
              });
            }).catch((error) => {
              // An error happened.
            })
  
            localStorage.clear()
  
            window.location = `${process.env.BASE_URL}`
          }
        })
      } else {
        this.$router.push(`/master/store`)
      }
    }
  }
}
</script>

<style>
 .v-responsive__content {
   width: 100% !important;
 }
</style>